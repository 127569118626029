import React, { useState } from 'react';
import { Card, CardContent, Grid, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import Loader from "react-loader-spinner";
import circleFlag from '../../../assets/img/UsdBrlCircleFlag.png';

const useStyles = makeStyles(() => ({
    cardContainer: {
        marginLeft: 20,
        borderRadius: 10,
        backgroundColor: '#f0f0f0',
        maxWidth: '100%',
        paddingBottom: 10
    },
    cardContent: {
        marginTop: -30,
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
    },
    paperContainer: {
        borderRadius: 10,
        overflow: 'auto',
        flex: '1 1 auto',
    },
    paperStyle: {
        borderRadius: 10,
        overflow: 'auto',
        maxHeight: '45%',
    },
    tableContainer: {
        maxHeight: '100%',
    },
    boldAndBlack: {
        fontWeight: 'bold',
        color: 'black',
    },

    loader: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row'
    }
}));



const Resultado = ({ data, dolar, calculando, dataMisc, vendedor }) => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);

    if (calculando) {
        return (
            <div className={classes.loader}>
                Carregando...
                <Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={20000}
                />
            </div>
        )
    }

    if (Object.keys(data).length <= 0) {
        return <span></span>
    }


    const orderedKeys = ['total_N', 'total_P', 'total_K', 'total_S', 'total_Ca', 'total_Zn', 'total_B', 'total_Cu', 'total_Fe', 'total_Mg', 'total_Mn', 'total_S-SO4'];
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const ResultadoGeral = (data_d) => {
        if (Object.keys(data_d.data_d).length === 1 && 'dolar' in data_d.data_d) {
            return <div>Sem fechamentos exatos</div>
        }
        return (
            <CardContent className={classes.cardContent}>
                <Grid container spacing={3}>
                    <Grid item xs={7}>
                        <h3>Composição</h3>
                        <div className={classes.flexColumn}>
                            <Paper className={classes.paperContainer}>
                                <TableContainer className={classes.tableContainer}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Composto</TableCell>
                                                <TableCell>Quantidade</TableCell>
                                                {vendedor == false ? <TableCell>Preço</TableCell> : null}
                                                <TableCell>Preço Venda </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries(data_d.data_d.composicao).map(([key, value]) => (
                                                <TableRow key={key}>
                                                    <TableCell>{key}</TableCell>
                                                    <TableCell>{value} KG</TableCell>
                                                    {vendedor == false ? <TableCell>{(data_d.data_d.precos[key] * 1000 / value).toFixed(2)}</TableCell> : null}
                                                    <TableCell>
                                                        {((data_d.data_d.precos[key] * 1000 / value) + parseFloat(data_d.data_d.fixos)).toFixed(2)}/Ton
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <h3>Preços</h3>
                        <Paper className={classes.paperStyle}>
                            <TableContainer className={classes.tableContainer}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Custos Fixos</TableCell>
                                            <TableCell>${data_d.data_d.fixos.toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Custo Total Formulação</TableCell>
                                            <TableCell>${data_d.data_d.totais.preco_total_dolar.toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.boldAndBlack}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={circleFlag} alt="USD-BRL Flag" width="20" height="20" style={{ marginRight: '8px' }} />
                                                    Preço Total
                                                </div>
                                                <div>{`($${dolar.toFixed(2)})`}</div>
                                            </TableCell>
                                            <TableCell className={classes.boldAndBlack}>
                                                <div>
                                                    R$ {(data_d.data_d.totais.preco_total + data_d.data_d.fixos * dolar).toFixed(2)}
                                                    <br />
                                                    USD {(data_d.data_d.totais.preco_total_dolar + data_d.data_d.fixos).toFixed(2)}
                                                </div>
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <h3>Nutrientes</h3>
                        <Paper className={classes.paperStyle}>
                            <TableContainer className={classes.tableContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nutriente</TableCell>
                                            <TableCell>%</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderedKeys.map(key => {
                                            const value = data_d.data_d.totais[key];
                                            if (value !== 0) {
                                                return (
                                                    <TableRow key={key}>
                                                        <TableCell>{key.replace('total_', '')}</TableCell>
                                                        <TableCell>{value} %</TableCell>
                                                    </TableRow>
                                                );
                                            }
                                            return null;
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </CardContent>)
    }
    const getTabLabel = (fertilizer) => {
        if (!dataMisc[fertilizer] || !dataMisc[fertilizer].totais) {
            return "Dados não disponíveis"; // Ou alguma outra string que você preferir
        }

        const totals = dataMisc[fertilizer].totais;
        const roundedN = totals.total_N.toFixed(1);
        const roundedP = totals.total_P.toFixed(1);
        const roundedK = totals.total_K.toFixed(1);
        return `N:${roundedN} - P:${roundedP} - K:${roundedK}`;
    }

    return (
        <Card className={classes.cardContainer} elevation={0}>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="formula tabs">
                <Tab label="Retorno" />
                {dataMisc['Nitrato'] && dataMisc['Nitrato'].totais && <Tab label={'Outros ' + getTabLabel('Nitrato')} />}
                {dataMisc['Ureia'] && dataMisc['Ureia'].totais && <Tab label={getTabLabel('Ureia')} />}
                {dataMisc['Super Simples/TSP'] && dataMisc['Super Simples/TSP'].totais && <Tab label={getTabLabel('Super Simples/TSP')} />}
            </Tabs>
            {activeTab === 0 ?
                <ResultadoGeral data_d={data}></ResultadoGeral> : null}
            {activeTab === 1 && dataMisc['Nitrato'] && dataMisc['Nitrato'].totais ?
                <ResultadoGeral data_d={dataMisc['Nitrato']}></ResultadoGeral> : null}
            {activeTab === 2 && dataMisc['Ureia'] && dataMisc['Ureia'].totais ?
                <ResultadoGeral data_d={dataMisc['Ureia']}></ResultadoGeral> : null}
            {activeTab === 3 && dataMisc['Super Simples/TSP'] && dataMisc['Super Simples/TSP'].totais ?
                <ResultadoGeral data_d={dataMisc['Super Simples/TSP']}></ResultadoGeral> : null}
        </Card>
    );
};

export default Resultado;
