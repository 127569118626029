import axios from 'axios';
let link = window.location.href
let url = ''
if (link.includes('http://localhost:3000/')) {
  url = 'http://localHost:5002/'
}
else {
  url = 'https://back.macs.dev.br:5002/'
}

const api2 = axios.create({
  baseURL: url,

});
console.log(link)
export default api2;
