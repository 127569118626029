import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Box,
    Grid,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Checkbox,
    Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from 'services/api.js';
import { makeStyles } from "@material-ui/core";
import { getUser, getToken } from 'utils/utils.js';
import Tooltip from '@material-ui/core/Tooltip';

export default function Calcular({ materias, onFormulacao,onFormulacaoMisc, onFetching, calculando }) {
    const classes = useStyles()
    const [n, setN] = useState(0)
    const [p, setP] = useState(0)
    const [origem, setOrigem] = useState(0)
    const [antidusting, setAntidusting] = useState(false)

    const [k, setK] = useState(0)
    const [materiasMustUse, setMateriasMustUse] = useState([])
    const [materiasMustNotUse, setMateriasMustNotUse] = useState([])
    const [materialAmounts, setMaterialAmounts] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [currentMaterial, setCurrentMaterial] = useState(null);
    const [tempAmounts, setTempAmounts] = useState({ min: 50, max: 1000 });
    const [pendingMaterial, setPendingMaterial] = useState(null);
    const [selectedMaterias, setSelectedMaterias] = useState([]);


    const [elementValues, setElementValues] = useState({
        'S': 0,
        'Ca': 0,
        'Zn': 0,
        'B': 0,
        'Cu': 0,
        'Fe': 0,
        'Mg': 0,
        'Mo': 0,
        'Mn': 0,
        'S-SO4': 0,
    })

    useEffect(() => {
        if (currentMaterial) {
            setMaterialAmounts(prev => ({
                ...prev,
                [currentMaterial.cod_total]: { min: 50, max: 1000 }
            }));
        }
    }, [currentMaterial]);

    console.log("MaterialAmounts", materialAmounts)
    const elementos = [
        { label: 'Enxofre (S)', key: 'S' },
        { label: 'Calcio (Ca)', key: 'Ca' },
        { label: 'Zinco (Zn)', key: 'Zn' },
        { label: 'Boro (B)', key: 'B' },
        { label: 'Cobre (Cu)', key: 'Cu' },
        { label: 'Ferro (Fe)', key: 'Fe' },
        { label: 'Magnésio (MG)', key: 'MG' },
        { label: 'Molibdênio (Mo)', key: 'Mo' },
        { label: 'Manganês (Mn)', key: 'Mn' },
        { label: 'S-SO4', key: 'S-SO4' }
    ]

    const handleNPKInput = (value, key) => {
        switch (key) {
            case 'N':
                setN(value)
                break
            case 'P':
                setP(value)
                break
            case 'K':
                setK(value)
                break
        }
    }

    const handleElementInputChange = (value, key) => {
        const numericValue = value === '' ? 0 : parseFloat(value);

        setElementValues({
            ...elementValues,
            [key]: isNaN(numericValue) ? 0 : numericValue
        });
    }

    console.log(elementValues)

    async function handleCalculo() {
        onFetching(true)
        const materiasToUse = materiasMustUse.map(material => material.cod_total);
        const materiasToAvoid = materiasMustNotUse.map(material => material.cod_total);

        const response_pedidos = await api.get("/retorna_formulacoes_teste", {
            params: {
                n,
                p,
                k,
                userId: getUser(), token: getToken(),
                materiasToUse: JSON.stringify(materiasToUse),
                materiasToAvoid: JSON.stringify(materiasToAvoid),
                elementValues: JSON.stringify(elementValues),
                materiasToUse: JSON.stringify(materiasToUse),
                materialAmounts: JSON.stringify(materialAmounts),
                origem,
                antidusting:antidusting === true ? 1 : 0
            },

        })
        console.log(response_pedidos.data )
        if ((response_pedidos.data === "NOS")) {
            alert('Não foi possível encontrar uma formulação para os valores informados')
        }
        else {
            try {
                
                if (Object.keys(response_pedidos.data[0].retorno).length === 0) {
                    alert('Não foi possível encontrar uma formulação para os valores informados');
                }
                onFormulacao(response_pedidos.data[0].retorno)
                onFormulacaoMisc(response_pedidos.data[0].misc)
            } catch (error) {

                // Handle the error
                alert('Não foi possível encontrar uma formulação para os valores informados');
            }
            
            console.log("r", response_pedidos.data[0].retorno)
        }
        onFetching(false)
    }

    const handleAutoCompleteChange = (event, value) => {
        // Check if an item has been removed
        if (value.length === 0) {
            setMaterialAmounts({});
            setSelectedMaterias(value);
            return;
        }
        if (value.length < selectedMaterias.length) {
            // Find the removed item
            const removedMaterial = selectedMaterias.find(
                materia => !value.includes(materia)
            );

            if (removedMaterial) {
                // Update materialAmounts by omitting the removed material's key
                setMaterialAmounts(prev => {
                    const updatedAmounts = { ...prev };
                    delete updatedAmounts[removedMaterial.cod_total];
                    return updatedAmounts;
                });
            }
        } else if (value.length > selectedMaterias.length) {
            // Check if an item has been added
            const newMaterial = value[value.length - 1];
            setCurrentMaterial(newMaterial);
            setPendingMaterial(newMaterial);
            setOpenDialog(true);
            // Return early to avoid updating selectedMaterias here.
            return;
        }

        // Update selected materials
        setSelectedMaterias(value);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setTempAmounts({ min: 0, max: 1000 });
    };

    const renderTooltipContent = () => {
        return Object.entries(materialAmounts)
            .map(([key, value]) => (
                <div key={key} style={{ marginBottom: 10 }}>
                    {key}: Min - {value.min}, Max - {value.max}
                </div>
            ));
    };

    const hasMaterialAmounts = Object.keys(materialAmounts).length > 0;

    return (
        <Box className={classes.mainContainer} >
            <Paper className={classes.mainPaper}>
                <div className={classes.titleCard}>
                    Formulador
                </div>
                <Grid container direction="row" xs={12} style={{ marginBottom: 20 }}>
                    {['N', 'P', 'K'].map((item, index) =>
                        <Grid item xs={4} key={index} className={classes.npk} >
                            <b>{item}</b>
                            <TextField
                                type='number'
                                onChange={(e) => handleNPKInput(e.target.value, item)}
                                InputProps={{
                                    classes: {
                                        input: classes.thinInput,
                                    },
                                }}
                                style={{ width: '90%' }} size="small" variant="outlined" />
                        </Grid>)}
                </Grid>

                <Grid container>
                    {elementos.map((item, index) => (
                        <Grid container item key={index} xs={12} className={classes.elemento}>
                            <Grid item xs={6}>
                                {item.label}
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    defaultValue={0}
                                    onChange={(e) => handleElementInputChange(e.target.value, item.key)}
                                    type='number'
                                    InputProps={{
                                        classes: {
                                            input: classes.thinInput,
                                        },
                                    }}
                                    size="small" variant="outlined" />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                <Grid container direction='column' className={classes.marginBetween}>
                    <Grid item xs={12}>
                        Incluir matéria obrigatória
                        <Autocomplete
                            onChange={(event, value) => {
                                if (value.length > materiasMustUse.length) {
                                    const newMaterial = value[value.length - 1];
                                    setCurrentMaterial(newMaterial);
                                    setOpenDialog(true);
                                } else {
                                    setMateriasMustUse(value);
                                }
                            }}
                            getOptionLabel={(option) => option.cod_total}
                            size='small'
                            multiple
                            options={materias}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        Excluir matéria obrigatória
                        <Autocomplete
                            onChange={(event, value) => setMateriasMustNotUse(value)}
                            getOptionLabel={(option) => option.cod_total}
                            size='small'
                            multiple
                            options={materias}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Grid>
                    <Grid container direction="column" justifyContent="space-between" alignItems="center">
                        <Grid item direction="row" >
                            <Typography>Barcarena  <Checkbox
                                checked={origem === 0}
                                onChange={(e) => setOrigem(0)}
                            /></Typography>

                        </Grid>


                        <Grid item>
                            <Typography>Paranaguá  <Checkbox
                                checked={origem === 1}
                                onChange={(e) => setOrigem(1)}
                            /></Typography>
                        </Grid>

                        <Grid item>
                            <Typography>Bom Jesus - GO  <Checkbox
                                checked={origem === 2}
                                onChange={(e) => setOrigem(2)}
                            /></Typography>
                        </Grid>
                        <Grid item>
                            <Typography>AntiDusting  <Checkbox
                                checked={antidusting}
                                onChange={(e) => setAntidusting(!antidusting)}
                            /></Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container xs={12} direction='row' justifyContent='center' className={classes.marginBetween}>
                    <Button variant='contained' color="primary" size="small" onClick={handleCalculo} disabled={calculando}>
                        Calcular
                    </Button>
                </Grid>
            </Paper>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}>
                <DialogTitle>Set Amount for {currentMaterial?.cod_total}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Min"
                        type="number"
                        value={tempAmounts.min}
                        onChange={(e) => {
                            const value = e.target.value;
                            setTempAmounts(prev => ({ ...prev, min: value }));
                        }}
                        style={{ width: '20%', marginRight: 20 }} size="small" variant="outlined" />

                    <TextField
                        label="Max"
                        type="number"
                        value={tempAmounts.max}
                        onChange={(e) => {
                            const value = e.target.value;
                            setTempAmounts(prev => ({ ...prev, max: value }));
                        }}
                        style={{ width: '20%' }} size="small" variant="outlined"
                    />

                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color="red" size="small"
                        onClick={() => {
                            setOpenDialog(false);
                            setPendingMaterial(null);
                            // Remove the pending material from the selected items
                            setSelectedMaterias(prev => prev.filter(materia => materia !== pendingMaterial));
                        }} >
                        Cancelar
                    </Button>
                    <Button variant='contained' color="primary" size="small"
                        onClick={() => {
                            const newAmounts = { ...materialAmounts };
                            newAmounts[currentMaterial.cod_total] = tempAmounts;
                            setMaterialAmounts(newAmounts);
                            setSelectedMaterias(prev => [...prev, pendingMaterial]);
                            setPendingMaterial(null);
                            setOpenDialog(false);
                        }} >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}

const useStyles = makeStyles({
    mainContainer: {
        background: '#f0f0f0',
        padding: 20,
        borderRadius: 10
    },

    mainPaper: {
        padding: 10,
        borderRadius: 10
    },

    thinInput: {
        height: '20px',
        padding: '2px 10px',
        fontSize: '14px',
    },

    npk: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },

    titleCard: {
        background: '#6699FF',
        borderTopLeftRadius: 10,
        marginBottom: 10,
        borderTopRightRadius: 10,
        marginTop: -10,
        marginLeft: -10,
        marginRight: -10,
        padding: 10,
        color: 'white',
        fontWeight: 'bold'
    },

    elemento: {
        height: 30,
        display: 'flex',
        justifyContent: 'space-between'
    },

    marginBetween: {
        marginTop: 10
    },
})