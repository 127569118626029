import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../flux/actions/tokenIndex";
import { setUser } from "../flux/actions/userIndex";
import api from "../services/api";
import Loader from "react-loader-spinner";

function getUser() {
  const userString = localStorage.getItem('user')
  if (userString === null) {
    return ''
  }
  else {
    return userString
  }

}
function getLink() {
  const link = localStorage.getItem('link')
  if (link === null) {
    return ''
  }
  else {
    return link
  }

}

function SignInSide(props) {
  const [Login, setLogin] = useState("");
  const [Pass, setPass] = useState("");
  const [logando, setLogando] = useState(false)

  const classes = useStyles();


  useEffect(() => {
    if (getUser() !== '') {
      if (getLink() !== '') {
        props.history.push(getLink())
        localStorage.setItem('link', '')
      } else {
        props.history.push({ pathname: "/JornalGen" })
      }
    }
  }, [])


  async function validateLogin() {
    setLogando(true)
    const response_login = await api.get("/login", {
      params: { login: Login, senha: Pass },
    });

    if (response_login.data === 'NOK') {
      alert('Login inválido')
    } else {
      localStorage.setItem('user', response_login.data[0].nome)
      localStorage.setItem('permissoes', response_login.data[0].permissoes)
      localStorage.setItem('celular', response_login.data[0].celular)
      localStorage.setItem('token', response_login.data[0].token)
      localStorage.setItem('Prio', response_login.data[0].prio)
      localStorage.setItem('id', response_login.data[0].id)

      // if(response_login.data[0].nome === 'teste'){
      //   localStorage.setItem('Prio', '0')
      // }else if(response_login.data[0].nome === 'romer' || response_login.data[0].nome === 'VictorLog'||response_login.data[0].nome === 'Fred'){ localStorage.setItem('Prio', '2')}
      // else if(response_login.data[0].nome === 'fabricabj'){ localStorage.setItem('Prio', '3')}
      // else if(response_login.data[0].nome === 'Anaclaudia'){ localStorage.setItem('Prio', '4')}

      props.setToken(Pass);
      props.setUser(response_login.data[0].nome);

      window.location.reload();
    }
    setLogando(false)
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={5} md={12} component={Paper} elevation={6} square>
        <div className={classes.paper}>


          <form className={classes.form} noValidate>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="login"
                label="Login"
                name="login"
                autoComplete="login"
                autoFocus
                onChange={(event) => setLogin(event.target.value)}
                style={{ width: "80vw", maxWidth: 350 }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => setPass(event.target.value)}
                onKeyPress={(event) => event.key === 'Enter' && !logando ? validateLogin() : null}
                style={{ width: "80vw", maxWidth: 350 }}
              />

              {logando ?
                <div>
                  <Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={2000000}
                  />
                </div> :
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={validateLogin}
                  style={{ width: "25%", maxWidth: 350 }}
                >
                  Logar
                </Button>
              }

              <Grid container></Grid>

            </div>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "../../public/logo192.png",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToken: setToken,
      setUser: setUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInSide);
