import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import AssignmentIcon from '@material-ui/icons/Assignment';

// Paginas
import DashboardPage from "views/Dashboard/Dashboard.js";
import AtualizaFretes from "views/AtualizaFretes/AtualizaFretes.js"
import SmsSend from "views/SmsSend/SmsSend.js"
import Emprestimos from 'views/Emprestimos/Emprestimos.js';
import Notas from 'views/Notas/Notas.js';
import Vendas from 'views/VendasExcel/Vendas';
import NDF from 'views/NDF/NDF.js';
import TabelaMaterias from 'views/TabelaMaterias/TabelaMaterias.js'
import ValidaVenda from "views/ValidaVenda/ValidaVenda";
import Relatorios from "views/Relatorios/Relatorios";
import PowerBINecessidades from "views/PowerBINecessidades/PowerBINecessidades";
import Agendamento from 'views/Agendamento/Agendamento'
import AgendamentoOrdenado from 'views/AgendamentoOrdenado/AgendamentoOrdenado'
import JornalGen from "views/JornalGen/JornalGen";
import WorkflowComex from "views/WorkflowComex/WorkflowComex";
import ConfigurarUsuario from "views/ConfigurarUsuario/ConfigurarUsuario";
import ConfigurarConta from "views/ConfigurarConta/ConfigurarConta";
import HierarquiaVendas from "views/HierarquiaVendas/HierarquiaVendas";
import AutoFaturamento from "views/AutoFaturamento/AutoFaturamento";
import ConfigurarLogistica from "views/ConfigurarLogistica/ConfigurarLogistica";
import ConfigurarComercial from "views/ConfigurarComercial/ConfigurarComercial";
import MonitorCarregamentos from "views/MonitorCarregamentos/MonitorCarregamentos";
import InputsPricing from "views/InputsPricing/InputsPricing";
import PricingView from "views/PricingView/PricingView";
import AprovaVendaLogistica from "views/AprovaVendaLogistica/AprovaVendaLogistica";
import PlacasChecklist from "views/PlacasChecklist/PlacasChecklist";
import Necessidades from "views/Necessidades/Necessidades";
import EstadosPedidos from "views/EstadosPedidos/EstadosPedidos";
import GraficosTeste from "views/GraficosTeste/GraficosTeste";
import DocumentosColaborador from 'views/DocumentosColaborador/DocumentosColaborador';
import Pesquisas from "views/Pesquisas/Pesquisas";
import BalancoGeral from "views/BalancoGeral/BalancoGeral";
import PrecosDeCompra from "views/BalancoGeral/PrecosDeCompra";
import Formulador from "views/Formulador/Formulador";
import ControleMetas from "views/ControleMetas/ControleMetas";
import NewFormulador from "views/Formulador/NewFormulador";
import ClientesFiadores from "views/ClientesFiadores/ClientesFiadores";
import TabelaValorPauta from "views/TabelaValorPauta/TabelaValorPauta";


// Icones
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PermPhoneMsgOutlinedIcon from '@material-ui/icons/PermPhoneMsgOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShareIcon from '@material-ui/icons/Share';
import ListIcon from '@material-ui/icons/List';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import DescriptionIcon from '@material-ui/icons/Description';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Reorder from '@material-ui/icons/Reorder';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import InputIcon from '@material-ui/icons/Input';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FilterIcon from '@material-ui/icons/Filter';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import AssessmentIcon from '@material-ui/icons/Assessment';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import FlagIcon from '@material-ui/icons/Flag';

import TableChartIcon from '@material-ui/icons/TableChart';
import bgImage from "assets/img/sidebar-2.png";
import logo from "assets/img/reactlogo.png";
import Cards from "views/Cards/Cards";




let ps;

function getPrio() {
  const PrioString = localStorage.getItem('Prio')

  if (PrioString === null) {
    return ''
  }
  else {
    return PrioString
  }
}

function getPermissoes() {
  const permissoes = localStorage.getItem('permissoes')

  if (permissoes === null) {
    return ''
  }
  else {
    return JSON.parse(permissoes)
  }
}



function returnThing() {
  let dashboardRoutes = []
  let permissoes = getPermissoes()

  

  dashboardRoutes.push({
    path: "/JornalGen",
    name: "JornalGen",
    icon: DescriptionIcon,
    component: JornalGen,
    layout: "/admin"
  })
  

  if (permissoes === '') {
    return dashboardRoutes
  }
  if (permissoes[0]['clientes_fiadores'] === 1) {
    dashboardRoutes.push({
      path: "/ClientesFiadores",
      name: "Fiadores Clientes",
      icon: DescriptionIcon,
      component: ClientesFiadores,
      layout: "/admin"
    })
  }
  if (permissoes[0]['necessidades'] === 1) {
    dashboardRoutes.push({
      path: "/Necessidades",
      name: "Necessidades",
      icon: CheckCircleIcon,
      component: Necessidades,
      layout: "/admin"
    })
  }

  if (permissoes[0]['admin'] === 1) {
    dashboardRoutes.push({
      path: "/ConfigurarUsuario",
      name: "Configurar Usuário",
      icon: CheckCircleIcon,
      component: ConfigurarUsuario,
      layout: "/admin"
    })
  }

  if (permissoes[0]['configurarLogistica'] === 1) {
    dashboardRoutes.push({
      path: "/ConfigurarLogistica",
      name: "Configurações Logística",
      icon: Reorder,
      component: ConfigurarLogistica,
      layout: "/admin"
    })
  }

  if (permissoes[0]['dashboard'] === 1) {
    dashboardRoutes.push(
      {
        path: "/dashboard",
        name: "Relatórios",
        icon: ListIcon,
        component: DashboardPage,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['notas'] === 1) {
    dashboardRoutes.push(
      {
        path: "/notas",
        name: "Notas XP ",
        icon: MonetizationOnIcon,
        component: Notas,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['ndf'] === 1) {
    dashboardRoutes.push(
      {
        path: "/NDF",
        name: "NDF ",
        icon: ReceiptIcon,
        component: NDF,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['emprestimos'] === 1) {
    dashboardRoutes.push(
      {
        path: "/Emprestimos",
        name: "Emprestimos ",
        icon: ShareIcon,
        component: Emprestimos,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['sms'] === 1) {
    dashboardRoutes.push(
      {
        path: "/SMS",
        name: "Sistema de chamadas",
        icon: PermPhoneMsgOutlinedIcon,
        component: SmsSend,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['vendas'] === 1) {
    dashboardRoutes.push(
      {
        path: "/Vendas",
        name: "Contratos de Venda",
        icon: MonetizationOnIcon,
        component: Vendas,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['placas_checklist'] === 1) {
    dashboardRoutes.push(
      {
        path: "/PlacasChecklist",
        name: "Check List",
        icon: FilterIcon,
        component: PlacasChecklist,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['validaVendas'] === 1) {
    dashboardRoutes.push(
      {
        path: "/Aprovacao",
        name: "Aprovação de vendas",
        icon: PlaylistAddCheckIcon,
        component: ValidaVenda,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['hierarquiaVendas'] === 1) {
    dashboardRoutes.push(
      {
        path: "/hierarquiaVendas",
        name: "Aprovação de Vendas",
        icon: PlaylistAddCheckIcon,
        component: HierarquiaVendas,
        layout: "/admin"
      }
    )
  }


  if (permissoes[0]['aprovaVendaLogistica'] === 1) {
    dashboardRoutes.push(
      {
        path: "/AprovaVendaLogistica",
        name: "Aprovação de Fretes",
        icon: PlaylistAddCheckIcon,
        component: AprovaVendaLogistica,
        layout: "/admin"
      }
    )
  }
  if (permissoes[0]['analises_sysgen'] === 1) {
    dashboardRoutes.push(
      {
        path: "/GraficosTeste",
        name: "Análises Sysgen",
        icon: AssessmentIcon,
        component: GraficosTeste,
        layout: "/admin"
      }
    )
  }
  if (permissoes[0]['estados_pedidos'] === 1) {
    dashboardRoutes.push(
      {
        path: "/EstadosPedidos",
        name: "Status Pedidos",
        icon: DynamicFeedIcon,
        component: EstadosPedidos,
        layout: "/admin"
      }
    )
  }
  if (permissoes[0]['powerBiNecessidades'] === 1) {
    dashboardRoutes.push(
      {
        path: "/PowerBINecessidades",
        name: "Necessidades",
        icon: ShoppingCartIcon,
        component: PowerBINecessidades,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['materiasPrecos'] === 1) {
    dashboardRoutes.push(
      {
        path: "/materiasPrecos",
        name: "Tabela Matérias Preços",
        icon: AssignmentIcon,
        component: TabelaMaterias,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['tabelaFretes'] === 1) {
    dashboardRoutes.push(
      {
        path: "/tabelaFretes",
        name: "Tabela de Fretes",
        icon: AssignmentIcon,
        component: AtualizaFretes,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['agendamento'] === 1) {
    dashboardRoutes.push(
      {
        path: "/Agendamento",
        name: "Agendamento",
        icon: VerticalSplitIcon,
        component: Agendamento,
        layout: "/admin"
      }
    )
  }
  if (permissoes[0]['tabela_valores_pauta'] === 1) {
    dashboardRoutes.push(
      {
        path: "/TabelaValorPauta",
        name: "Tabela Valor Pautas",
        icon: ReceiptIcon,
        component: TabelaValorPauta,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['cards_pedidos'] === 1) {
    dashboardRoutes.push({
      path: "/Cards",
      name: "Cards",
      icon: DescriptionIcon,
      component: Cards,
      layout: "/admin"
    })
  }
    
  
  if (permissoes[0]['agendamento'] === 1) {
    dashboardRoutes.push(
      {
        path: "/AgendamentoOrdenado",
        name: "Ordenação Agendamentos Fábrica",
        icon: AvTimerIcon,
        component: AgendamentoOrdenado,
        layout: "/admin"
      }
    )
  }



  if (permissoes[0]['relatorios'] === 1) {
    dashboardRoutes.push(
      {
        path: "/Relatorios",
        name: "Relatorios+",
        icon: AssignmentIcon,
        component: Relatorios,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['workflowComex'] === 1) {
    dashboardRoutes.push(
      {
        path: "/WorkflowComex",
        name: "Workflow Comex",
        icon: DirectionsBoatIcon,
        component: WorkflowComex,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['autoFat'] === 1) {
    dashboardRoutes.push(
      {
        path: "/AutoFaturamento",
        name: "Agendamentos faturados",
        icon: ReceiptIcon,
        component: AutoFaturamento,
        layout: "/admin"
      }
    )
  }
  if (permissoes[0]['monitorCarregamentos'] === 1) {
    dashboardRoutes.push(
      {
        path: "/MonitorCarregamentos",
        name: "Monitor Carregamentos",
        icon: SearchIcon,
        component: MonitorCarregamentos,
        layout: "/admin"
      })
  }

  if (permissoes[0]['configComercial'] === 1) {
    dashboardRoutes.push(
      {
        path: "/ConfigurarComercial",
        name: "Configurar Comercial",
        icon: SettingsIcon,
        component: ConfigurarComercial,
        layout: "/admin"
      }
    )
  }
  if (permissoes[0]['inputsPricing'] === 1) {
    dashboardRoutes.push(
      {
        path: "/InputsPricing",
        name: "Inputs Pricing",
        icon: InputIcon,
        component: InputsPricing,
        layout: "/admin"
      }
    )
  }
  if (permissoes[0]['pricingView'] === 1) {
    dashboardRoutes.push(
      {
        path: "/PricingView",
        name: "Precificação",
        icon: AccountBalanceWalletIcon,
        component: PricingView,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['pesquisas'] === 1) {
    dashboardRoutes.push(
      {
        path: "/Pesquisas",
        name: "Pesquisas",
        icon: CalendarViewDayIcon,
        component: Pesquisas,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['documentosColaborador'] === 1) {
    dashboardRoutes.push(
      {
        path: "/DocumentosColaborador",
        name: "Arquivos RH",
        icon: AccountBoxIcon,
        component: DocumentosColaborador,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['balanco_geral'] === 1) {
    dashboardRoutes.push(
      {
        path: "/BalancoGeral",
        name: "Balanço Geral",
        icon: TableChartIcon,
        component: BalancoGeral,
        layout: "/admin"
      }
    )
  }

  if (permissoes[0]['precos_de_compra'] === 1) {
    dashboardRoutes.push(
      {
        path: "/PrecosDeCompra",
        name: "Preços de Compra",
        icon: TableChartIcon,
        component: PrecosDeCompra,
        layout: "/admin"
      }
    )
  }
  if (permissoes[0]['formulador'] === 1) {
    dashboardRoutes.push(
      {
        path: "/Formulador",
        name: "Formulador",
        icon: TableChartIcon,
        component: NewFormulador,
        layout: "/admin"
      }
    )
  }
  if (permissoes[0]['plr'] === 1) {
    dashboardRoutes.push(
      {
        path: "/ControleMetas",
        name: "Controle Metas",
        icon: FlagIcon,
        component: ControleMetas,
        layout: "/admin"
      }
    )
  }
 
  dashboardRoutes.push(
    {
      path: "/ConfigurarConta",
      name: "Configurar Conta",
      icon: AccountCircleIcon,
      component: ConfigurarConta,
      layout: "/admin"
    }
  )

  return dashboardRoutes
}

const switchRoutes = (
  <Switch>
    {returnThing().map((prop, key) => {
      if (prop.layout === "/admin") {
        console.log(1)
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/JornalGen" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const [mostrarSidebar, setMostrarSidebar] = React.useState(true)
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });

    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div>
      {mostrarSidebar ?
        <div style={{ position: 'fixed', left: '19.5%', zIndex: 1030 }}> {/* 1030 porque no headerStyle é 1029 (é la que fica o style da barra de cima transparente)*/}
          <button onClick={() => setMostrarSidebar(!mostrarSidebar)}>{'<'}</button>
        </div> :
        <div style={{ position: 'fixed', zIndex: 1030 }}>
          <button onClick={() => setMostrarSidebar(!mostrarSidebar)}>{'>'}</button>
        </div>}


      {mostrarSidebar ? <Sidebar
        routes={returnThing()}
        logoText={"SYSGEN"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      /> : null}

      <div className={mostrarSidebar ? classes.mainPanel : ''} ref={mainPanel}>
        <Navbar
          routes={returnThing()}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On //the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}


      </div>
    </div>
  );
}
