import React, { useEffect, useState } from "react";
import './Dashboard.css'
import api2 from "../../services/api2";

import { PowerBIEmbed } from "powerbi-client-react";
import { models } from 'powerbi-client'
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'

function PowerBINecessidades(props) {

  const [token, setToken] = useState('')
  const [permitido, setPermitido] = useState(false)


  useEffect(() => {
    async function fetchMyAPI() {
      await validateTokenUser(props)
      setPermitido(await checarPermissao('powerBiNecessidades', props))

      const response_token = await api2.get("/returnToken", { params: { userId: getUser(), token: getToken() } });
      setToken(response_token.data)
    }
    fetchMyAPI();

  }, [])



  return (
    permitido === true ?
      <div>
        <div style={{display:'flex',flexDirection:'column'}}>
          <a href="https://app.powerbi.com/view?r=eyJrIjoiZjcyOTc3MGMtOWM3Ni00YTRkLWIxODItOTcxMDMwM2VjNGY5IiwidCI6Ijc1M2UyM2VlLTE1YTYtNDQ0MS05ZmQxLWNkZmFmMDQ2YjhiYSJ9&pageName=ReportSection">
            Relatório Necessidades Embalagens
          </a>
          <a href="https://app.powerbi.com/view?r=eyJrIjoiM2ZlNjhlMmMtYjBkMC00MGNmLTk2YjQtNmFiZjM4MmQ1ZWI2IiwidCI6Ijc1M2UyM2VlLTE1YTYtNDQ0MS05ZmQxLWNkZmFmMDQ2YjhiYSJ9&pageName=ReportSection">
            Relatório Necessidades
          </a>
  
        </div>
        <div style={{ width: '2000', height: '2000', overflowX: 'auto' }}>
          <PowerBIEmbed
            embedConfig={{
              type: 'report',   // Supported types: report, PowerBINecessidades, tile, visual and qna
              id: 'a7172e3c-909e-4c46-b72e-c2bf81a60d53',
              embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=a7172e3c-909e-4c46-b72e-c2bf81a60d53&groupId=a44499b9-7ff6-43a7-816b-c97dc16cff3f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1CLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJjZXJ0aWZpZWRUZWxlbWV0cnlFbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
              accessToken: token,
              tokenType: models.TokenType.Aad,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false
                  }
                },

              }
            }}

            eventHandlers={
              new Map([
                ['loaded', function () { console.log('Report loaded'); }],
                ['rendered', function () { console.log('Report rendered'); }],
                ['error', function (event) { console.log(event.detail); }]
              ])
            }

            cssClassName={"Embed-container"}

            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>

      </div> : <></>
  );
}

export default PowerBINecessidades;
