import React, { useEffect, useState } from "react";
import { useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from '@material-ui/core/IconButton';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './agendamentoStyle.css'

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';


import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { checarPermissao, validateTokenUser, getUser, getToken } from '../../utils/utils.js'

import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Loader from "react-loader-spinner";
import exportFromJSON from "export-from-json";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import parse from 'html-react-parser';

const useStyles = makeStyles(styles)






 

function Agendamento(props) {
  const [praca, setPraca] = useState('')
  const [localCarregamento, setLocalCarregamento] = useState('')
  const [obs, setObs] = useState('')
  const [pedFatCliente, setPedFatCliente] = useState('')
  const [produto, setProduto] = useState('')
  const [quantidade, setQuantidade] = useState(0)
  const [embalagem, setEmbalagem] = useState('')
  const [cliente, setCliente] = useState('')
  const [roteiro, setRoteiro] = useState('')
  const [destino, setDestino] = useState('')
  const [transportadora, setTransportadora] = useState('')
  const [frete, setFrete] = useState(0)
  const [contato, setContato] = useState('')
  const [placa, setPlaca] = useState('')
  const [nomeMotorista, setNomeMotorista] = useState('')
  const [agendamentoArray, setAgendamentoArray] = useState('')
  const [agendamento, setAgendamento] = useState('')
  const [auxAgendamento, setAuxAgendamento] = useState('')
  const [numerosPedidosGen, setNumerosPedidosGen] = useState('')
  const [listaEmails, setListaEmails] = useState('')
  const [email, setEmail] = useState([])
  const [emailAlterado, setEmailAlterado] = useState('')

  const [numeroDI, setNumeroDI] = useState('')
  const [nomeNavio, setNomeNavio] = useState('')
  const [tipoCarregamento, setTipoCarregamento] = useState('')
  const [file, setFile] = useState([])
  const [placaQuantidade, setPlacaQuantidade] = useState([{ placa: '', quantidade: 0, dataChegada: '' }])
  const [arquivosSelecionados, setArquivosSelecionados] = useState([])
  const [historicoExcel, setHistoricoExcel] = useState([])
  const [itemCode, setItemCode] = useState('')
  const [cidades, setCidades] = useState('')
  const [listaTransportadoras, setListaTransportadoras] = useState('')
  const [listaProdutos, setListaProdutos] = useState('')
  const [listaArmazens, setListaArmazens] = useState('')
  const [abstFabrica, setAbstFabrica] = useState(false)
  const [stateArquivos, setStateArquivos] = useState(0)
  const [emailTransp, setEmailTransp] = useState('')

  // States para evitar mandar múltiplos inputs
  const [salvando, setSalvando] = useState(false)
  const [enviando, setEnviando] = useState(false)
  const [deletando, setDeletando] = useState(false)
  const [carteira, setCarteira] = useState(0)
  const [qtdAgendado, setQtdAgendado] = useState(0)

  const [placasAgendadas, setPlacasAgendadas] = useState('')
  const [nmPedido, setNmPedido] = useState('')

  const classes = useStyles()
  const [permitido, setPermitido] = useState(false)
  const [selectedAgendamento, setSelectedAgendamento] = useState(true)

  // states de pesquisa no histórico
  const [filtro, setFiltro] = useState('')
  const [filtroColuna, setFiltroColuna] = useState('')

  const [groupEmails, setGroupEmails] = useState([])
  const [emailGroup, setEmailGroup] = useState('')
  const [update_placas, setUpdate_placas] = useState([])

  const [abrirvizualizador,setAbrirVizualizador] = useState(false)
  const [tabela, setTabela] = useState('')
  const [html, setHtml] = useState("")
  
  const [relPng,  setRelPng] = useState('')
  useEffect(() => {
    async function fetchMyAPI() {
      await validateTokenUser(props)
      setPermitido(await checarPermissao('agendamento', props))

      const retornoAgendamento = await api.get('retornaAgendaDados', { params: { userId: getUser(), token: getToken() } })
      const retornoPedidos = await api.get('retornaNumerosPedidos', { params: { userId: getUser(), token: getToken() } })
      const retornoEmails = await api.get('retornaEmails', { params: { userId: getUser(), token: getToken() } })
      const retornoCidades = await api.get('returnDestinosInsert', { params: { userId: getUser(), token: getToken() } })
      const retornoTransportadoras = await api.get('retornaTransportadoras', { params: { userId: getUser(), token: getToken() } })
      const groupEmailsResponse = await api.get('/return_email_groups_agendamento', { params: { userId: getUser(), token: getToken() } })
      const retornoArmazens = await api.get('listaArmazens', { params: { userId: getUser(), token: getToken() } })

      const relPng = await api.get('/retorna_infos_placas_parangua', { params: { userId: getUser(), token: getToken() } })
      setAgendamento(retornoAgendamento.data)
      setAuxAgendamento(retornoAgendamento.data.slice(0, 30))
      setNumerosPedidosGen(retornoPedidos.data)
      setListaEmails(retornoEmails.data)
      setCidades(retornoCidades.data)
      setListaTransportadoras(retornoTransportadoras.data)
      setListaArmazens(retornoArmazens.data)
      setGroupEmails(groupEmailsResponse.data)
      gerarHistoricoExcel(retornoAgendamento.data)
      setRelPng(relPng.data)
    }
    fetchMyAPI();
  }, [])
  

 
  

 
  async function DownloadPDF(id){ 
    setAbrirVizualizador(true)
    setTabela()
    // var  teste ='<div id="capture">  <table style="align: right" border="1" class="dataframe">  <thead>    <tr style="text-align: right;">      <th></th>      <th>Tabela de informações</th>    </tr>  </thead>  <tbody>    <tr>      <th>Origem</th>      <td>Cabixi - RO</td>    </tr>    <tr>      <th>Armazem</th>      <td>Armazém Rocha Praça - Paranaguá PR</td>    </tr>    <tr>      <th>Número do Pedido</th>      <td>a0000256</td>    </tr>    <tr>      <th>Produto</th>      <td>F.M.A BR (B: 5% - Mn: 10% - Zn: 10%)</td>    </tr>    <tr>      <th>Navio</th>      <td>5</td>    </tr>    <tr>      <th>Embalagem</th>      <td>5</td>    </tr>    <tr>      <th>Cliente</th>      <td>5</td>    </tr>    <tr>      <th>Roteiro</th>      <td>5</td>    </tr>    <tr>      <th>Destino / UF</th>      <td>Cabixi - RO</td>    </tr>    <tr>      <th>Transportadora</th>      <td>2A CONTABILIDADE E ASSESSORIA LTDA</td>    </tr>    <tr>      <th>Nome do Motorista</th>      <td>5</td>    </tr>    <tr>      <th>Frete</th>      <td>5.00</td>    </tr>    <tr>      <th>Contato</th>      <td>5</td>    </tr>    <tr>      <th>Número DI</th>      <td>5</td>    </tr>    <tr>      <th>Tipo de Carregamento</th>      <td>Abastecimento Fábrica</td>    </tr>    <tr>      <th>Placa</th>      <td>Lote: a0000256-1 - Placa: 5 - Quantidade: 5.0 - Previsão de Chegada: 05/01/2023<br></td>    </tr>    <tr>      <th>Total</th>      <td>5.0</td>    </tr>  </tbody></table> </div>'
    const retornoHTML = await api.get('TabelaParaPDF', { params: { 'id': id, userId: getUser(), token: getToken() } })
    setHtml(retornoHTML.data)

  }
  // A função 'imprime' o conteudo de div marcada com o id abaixo
  // <div id="capture"></div>
  function printDocument(){
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 50, 10);
      pdf.save("Agendamento.pdf");
      setHtml("")
      setAbrirVizualizador(false);
    });
  
  }

  const handleClickClose2 = () => {
    setHtml("")
    setAbrirVizualizador(false);
};
  async function saveCarregamento() {
    let aux = 0

    placaQuantidade.forEach((item) => {
      aux += parseFloat(item['quantidade'])
    })

    if (((parseFloat(qtdAgendado) + aux) > parseFloat(carteira)) && (tipoCarregamento != 'Abastecimento Fábrica')) {//&& (cancelarPlacas === false) 

      return alert('Quantidade agendada excede o valor pendente na carteira do pedido, total Carteira = ' + carteira + '\nTotal Agendamentos pendentes + agendamento atual =' + (qtdAgendado + parseInt(aux)))
    }

    let groupEmailString = handleGroupEmailString()
    setSalvando(true)
    const response_save_carregamento = await api.get('agendaCarregamento', {
      params: {
        praca,
        localCarregamento,
        obs,
        pedFatCliente,
        produto,
        quantidade,
        embalagem,
        cliente,
        roteiro,
        destino,
        transportadora,
        frete,
        contato,
        placa,
        nomeMotorista,
        'email': email.toString(),
        numeroDI,
        nomeNavio,
        tipoCarregamento,
        itemCode,
        'file': '',
        'placaQuantidade': JSON.stringify(placaQuantidade),
        userId: getUser(),
        token: getToken(),
        'emailTransp': emailTransp.toString(),
        groupEmailString
      }
    })
    if (response_save_carregamento.data === 'OK') {
      let count = 0
      for (const x of file) {//Enviar arquivo a arquivo
        count += 1
        setStateArquivos(count)
        const response_save_arquivo = await api.post('insereArquivo',
          {
            pedFatCliente,
            file: x,
            userId: getUser(),
            token: getToken()
          }
        )

      }
      alert('Agendamento inserido com sucesso!')
      window.location.reload()
    } else {
      alert('Erro ao inserir agendamento, entre em contato com o time de desenvolvimento...')
    }
    setSalvando(false)
  }

  async function deletaRota(id, valor) {
    setDeletando(true)
    const agendamento = await api.get('deletaRotaAgendamento', { params: { id, valor, userId: getUser(), token: getToken() } })
    if (agendamento.data === 'OK') {
      alert('Agendamento deletado!')
      window.location.reload()
    } else {
      alert('Erro ao deletar, entre em contato com o time de desenvolvimento...')
    }
    setDeletando(false)
  }

  // Pega a linha do banco com todos os dados do pedido
  async function getDadosPedido(numPedido) {
    setNmPedido(numPedido)
    const retornoDados = await api.get('retornaDadosPedido', { params: { 'numPedido': numPedido, userId: getUser(), token: getToken(), produto: '' } })
    autoCompletaInput(retornoDados.data)
    const retornoProdutos = await api.get('/ItensCarregamento', { params: { 'numPedido': numPedido, userId: getUser(), token: getToken() } })
    setListaProdutos(retornoProdutos.data)

  }

  // Autocompleta os campos do front
  function autoCompletaInput(dados) {
    setCliente(dados[0]['NomeCliente'] || '')
    setEmbalagem(dados[0]['Embalagem'] || '')
    setRoteiro(dados[0]['U_U_Roteiro'] || '')
    setFrete(dados[0]['frete'] || 0)
    setContato(dados[0]['contato'] || '')
    //setProduto(dados[0]['NomeItem'] || '')
    setItemCode(dados[0]['itemCode'] || '')
  }

  async function enviarEmail(id, valor) {
    setEnviando(true)
    const agendamento = await api.get('enviarEmail', { params: { id, valor, userId: getUser(), token: getToken() } })
    if (agendamento.data === 'NOK1') {
      alert('Agendamento enviado!')
      window.location.reload()
    } else if (agendamento.data === 'OK') {
      alert('Erro ao enviar email, entre em contato com o time de desenvolvimento...')
    } else {
      alert('Erro, entre em contato com o time de desenvolvimento...')
    }
    setEnviando(false)
  }


  async function filesToBase64() { // get file and set state
    var fileInput = document.getElementById("myfileinput");
    var files = fileInput.files;
    var fileLocal;

    var aux = [] // Vetor auxiliar para guardar os multiplos arquivos, não dá para usar state por culpa dos ciclos de renderização

    for (var i = 0; i < files.length; i++) {

      fileLocal = files[i];
      let base64 = await toBase64(fileLocal)

      aux = [...aux, base64]
    }
    setFile(aux) // Seta depois que todos os arquivos estão dentro do vetor aux
  }

  const toBase64 = file => new Promise((resolve, reject) => {//converto to base 64
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });


  function camposObrigatorios() {
    let validaPlacas = 0
    let testePlaca = [...placaQuantidade]

    testePlaca.map((item) => {
      Object.values(item).map((valor) => {
        if (valor === '') {

          validaPlacas++
        }
      })
    })

    let validaString =
      (
        (praca === '' || praca === null ? 'Origem \n' : '') +
        (localCarregamento === '' || localCarregamento === null ? 'Local de Carregamento \n' : '') +
        (obs === '' ? 'Observação \n' : '') +
        (tipoCarregamento !== 'Abastecimento Fábrica' ? (pedFatCliente === '' || pedFatCliente === null ? 'Número do Pedido \n' : '') : '') +
        (produto === '' ? 'Produto \n' : '') +
        (embalagem === '' ? 'Embalagem \n' : '') +
        (cliente === '' ? 'Cliente \n' : '') +
        (roteiro === '' ? 'Roteiro \n' : '') +
        (destino === '' || destino === null ? 'Destino \n' : '') +
        (transportadora === '' ? 'Transportadora \n' : '') +
        (contato === '' ? 'Contato \n' : '') +
        (tipoCarregamento === '' || tipoCarregamento === null ? 'Tipo de Carregamento \n' : '') +
        (frete === 0 ? 'Frete \n' : '') +
        (validaPlacas !== 0 ? 'Placas' : '')
      )

    if (validaPlacas === 0 && validaString === '') { return true } else { alert('Preencha os seguintes campos: \n' + validaString) }
  }

  function mudarInput(valor, index, nome) {
    let lista = [...placaQuantidade]
    lista[index][nome] = valor
    setPlacaQuantidade(lista)
  }

  function gerarHistoricoExcel(dados) {
    let aux = JSON.parse(JSON.stringify(dados.filter(hist => hist.idClick === 1))) // .parse e .stringify pra poder fazer uma cópia do objeto
    aux.map(item => {
      let frufruzado = ''
      frufruzado = JSON.parse(item.placa).map(falcatrua => `${falcatrua.placa} - ${falcatrua.quantidade} - ${falcatrua.dataChegada}`)
      item.placa = frufruzado.toString()
    })
    aux.map(item => (delete item.email, delete item.arquivo, delete item.idClick, delete item.nomeMotorista, delete item.quantidade, delete item.loteControle))
    setHistoricoExcel(aux)
  }

  function baixarHistorico() {
    const data = historicoExcel
    const fileName = 'historicoAgendamentos'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }
  
  function baixarRelPgn() {
    const data = relPng
    const fileName = 'historicoCarregamentosPng'
    const exportType = exportFromJSON.types.xls

    exportFromJSON({ data, fileName, exportType })
  }


  async function abstState(valor) {
    if (valor === 'Abastecimento Fábrica') {
      setAbstFabrica(true)
      const retornoProdutos = await api.get('/Itens', { params: { userId: getUser(), token: getToken() } })
      setListaProdutos(retornoProdutos.data)
    } else {
      setAbstFabrica(false)
    }
    setPedFatCliente('')
    setLocalCarregamento('')
    setDestino('')
    setPraca('')
    setTransportadora('')
    setFrete(0)
    setEmbalagem('')
    setProduto('')
    setCliente('')
    setObs('')
    setContato('')
    setRoteiro('')
    setEmail('')
    setNumeroDI('')
    setNomeNavio('')
    setPlacaQuantidade([{ placa: '', quantidade: 0, dataChegada: '' }])
  }

  async function setCodigoProduto(valor) {

    if (valor !== '' && valor !== null) {
      let [codigo, ...produto] = valor.split(' - ')
      produto = produto.join(' - ')

      setProduto(produto)
      setItemCode(codigo)
      if (tipoCarregamento != 'Abastecimento Fábrica') {
        const retornoDados = await api.get('retornaDadosPedido', { params: { 'numPedido': nmPedido, userId: getUser(), token: getToken(), produto: produto } })
        setQtdAgendado(retornoDados.data[0]['TotalAgendadoPendente'])
        setCarteira(retornoDados.data[0]['Carteira'])
        const responsePlacas = await api.get('/retornaPlacasPorPedido', { params: { 'numPedido': nmPedido, userId: getUser(), token: getToken(), produto } })
        setPlacasAgendadas(responsePlacas.data)       
      }
    }

  }
  async function removePlacaAgendada(placa, id, index) {
    const removePlaca = await api.get('/removePlacaAgendada', { params: { placa, userId: getUser(), token: getToken(), id } })
    if (removePlaca.data === 'OK') { alert('placa removida com sucesso') }
    const retornoDados = await api.get('retornaDadosPedido', { params: { 'numPedido': nmPedido, userId: getUser(), token: getToken(), produto: produto } })
    setQtdAgendado(retornoDados.data[0]['TotalAgendadoPendente'])
    setCarteira(retornoDados.data[0]['Carteira'])
    const responsePlacas = await api.get('/retornaPlacasPorPedido', { params: { 'numPedido': nmPedido, userId: getUser(), token: getToken(), produto } })
    setPlacasAgendadas(responsePlacas.data)

  }

  function removerPlaca() {
    let aux = [...placaQuantidade]
    if (aux.length > 1) {
      aux.pop()
    }
    setPlacaQuantidade(aux)
  }

  const filtroColunaOptions = [
    { label: 'Data de Envio', value: 'data' },
    { label: 'Origem', value: 'praca' },
    { label: 'Placa', value: 'placa' },
    { label: 'Local de Carregamento', value: 'localCarregamento' },
    { label: 'Obs', value: 'obs' },
    { label: 'Número do Pedido', value: 'pedFatCliente' },
    { label: 'Produto', value: 'produto' },
    { label: 'Embalagem', value: 'embalagem' },
    { label: 'Cliente', value: 'cliente' },
    { label: 'Roteiro', value: 'roteiro' },
    { label: 'Destino', value: 'destino' },
    { label: 'Transportadora', value: 'transportadora' },
    { label: 'Frete', value: 'frete' },
    { label: 'Email', value: 'email' },
    { label: 'Número DI', value: 'numeroDI' },
    { label: 'Tipo de Carregamento', value: 'tipoCarregamento' },
    { label: 'Nome do Navio', value: 'nomeNavio' },
    { label: 'Contato', value: 'contato' }]






    

  const handleFiltro = (coluna_filtro, valor_filtro) => {
    if (coluna_filtro === '' || valor_filtro === '') { return }

    valor_filtro = valor_filtro.toLowerCase().trim()
    setAuxAgendamento(agendamento.filter(function (e) {
      return e[coluna_filtro].toLowerCase().trim().includes(valor_filtro)
    }))
  };

  function clearFiltro() {
    setFiltro('')
    setAuxAgendamento(agendamento.slice(0, 30))
  }
  async function montaUpdateEmail(id_) {
    if (emailAlterado !== '') {
      const alteraEmail = await api.get('/alteraEmailAgendamentoPendente', { params: {emailAlterado : emailAlterado, id_ : id_, token: getToken() } })
      if(alteraEmail.data === 'OK'){
        alert('Email Alterado com Sucesso')
        window.location.reload()
      }
      else{
        alert('Email Não Foi Alterado')

      }
    }
    
    console.log(emailAlterado)}

  function handleGroupEmailString() {
    let aux = groupEmails.filter((item) => emailGroup.includes(item.nome_grupo))
    let emailString = ''
    aux.map(item => item.emails.map(email => emailString += email + ','))
    emailString = emailString.slice(0, -1) // remove o ultimo character

    return emailString
  }
  function setUpdatePlacas(value, index, tipo) {

    let aux = update_placas
    aux[index + '#' + tipo] = value
    console.log(aux)
    setUpdate_placas(aux)
  }
  async function salvarInformacoes() {
    let auxO = ''
    Object.keys(update_placas).forEach(
      (item) => (auxO = auxO + item + '#' + update_placas[item] + ';')
    )
    const atualiza = await api.get('/salvaInformacoesExtraPlacasPedido', { params: { operacoes: auxO, userId: getUser(), token: getToken() } })
    if (atualiza.data === 'OK') { alert('placas atualizadas com sucesso') }


  }
  async function alterSelected(index, selectedAgendamento) {

    let aux = Array.from({ length: agendamentoArray.length }, i => i = false)
    aux[index] = selectedAgendamento
    setAgendamentoArray(JSON.parse(JSON.stringify(aux)))
    if (selectedAgendamento === true) {
      setSelectedAgendamento(false)
    } else {
      setSelectedAgendamento(true)
    }
  }


  
  return (
    permitido === true ?

      <div>       
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader style={{ backgroundColor: '#6699ff' }}>
                <h4 className={classes.cardTitleWhite}>Agendamento</h4>
                <p className={classes.cardCategoryWhite}>Agendamento de caminhões</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem>
                    <Autocomplete
                      value={tipoCarregamento}
                      getOptionSelected={() => true}
                      id='tipoCarregamento'
                      style={{ width: 300, margin: 10 }}
                      options={['Entrega Clientes', 'Abastecimento Fábrica', 'Vinculo Placas Paranagua','Fila Armazém BJ']}
                     
                      onChange={(event, newValue) => { setTipoCarregamento(newValue); abstState(newValue) }}
                      renderInput={(params) => (<TextField value={tipoCarregamento} variant='outlined' {...params} label='Tipo de Carregamento' />)}
                    />
                    {/* */}
                  </GridItem>
                </GridContainer>


                








                <GridContainer> {/* PRIMEIRA LINHA */}
                  <GridItem style={{ display: 'flex', flexDirection: 'row' }}>
                    {numerosPedidosGen !== '' ? <Autocomplete
                      value={pedFatCliente}
                      getOptionSelected={() => true}
                      id='numeroPedido'
                      disabled={abstFabrica || tipoCarregamento === '' || tipoCarregamento === null}
                      style={{ width: 300, margin: 10 }}
                      options={Object.keys(numerosPedidosGen).map((item, index) => (numerosPedidosGen[item].numeroPedidoGen))}
                      onChange={(event, newValue) => { getDadosPedido(newValue); setPedFatCliente(newValue) }}
                      renderInput={(params) => (<TextField variant='outlined' value={pedFatCliente} {...params}  label='Número do Pedido' />)}
                    /> : <Loader
                      type="Circles"
                      color="#00BFFF"
                      height={20}
                      width={20}
                      timeout={2000000} // 3 secs
                    />}
                    

                    <Autocomplete
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      value={localCarregamento}
                      getOptionSelected={() => true}
                      id='localCarregamento'
                      style={{ width: 300, margin: 10 }}
                      freesolo='true'
                      options={Object.keys(listaArmazens).map((item) => listaArmazens[item].armazem)}
                      onChange={(index, valor) => setLocalCarregamento(valor)}
                      renderInput={(params) => (<TextField value={localCarregamento} variant='outlined' {...params} label='Local de Carregamento' />)}
                    />

                    <Autocomplete
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      value={destino}
                      getOptionSelected={() => true}
                      id='destino'
                      style={{ width: 300, margin: 10 }}
                      options={Object.keys(cidades).map((item, index) => (cidades[item].City_uf))}
                      onChange={(index, valor) => setDestino(valor)}
                      renderInput={(params) => (<TextField value={destino} variant='outlined' {...params} label='Destino / UF' />)}
                    />
                  </GridItem>


                  <GridItem style={{ display: 'flex', flexDirection: 'row' }}>
                    <Autocomplete
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      value={praca}
                      getOptionSelected={() => true}
                      id='origem'
                      style={{ width: 300, margin: 10 }}
                      options={Object.keys(cidades).map((item, index) => (cidades[item].City_uf))}
                      onChange={(index, valor) => setPraca(valor)}
                      renderInput={(params) => (<TextField value={praca} variant='outlined' {...params} label='Origem' />)}
                    />

                    <Autocomplete
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      value={transportadora}
                      getOptionSelected={() => true}
                      id='transportadora'
                      style={{ width: 300, margin: 10 }}
                      options={Object.keys(listaTransportadoras).map((item, index) => (listaTransportadoras[item].transportadora))}
                      onChange={(index, valor) => setTransportadora(valor)}
                      renderInput={(params) => (<TextField value={transportadora} variant='outlined' {...params} label='Transportadora' />)}
                    />

                    <TextField id='frete'
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      style={{ width: 300, margin: 10 }}
                      variant='outlined'
                      value={frete}
                      label='Frete'
                      type='number'
                      onChange={(e) => setFrete(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer> {/* SEGUNDA LINHA */}
                  <GridItem style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField id='embalagem'
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      style={{ width: 300, margin: 10 }}
                      value={embalagem}
                      variant='outlined'
                      label='Embalagem'
                      onChange={(e) => setEmbalagem(e.target.value)}
                    />


                    <Autocomplete
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      id='produto'
                      style={{ width: 300, margin: 10 }}
                      options={Object.keys(listaProdutos).map((item, index) => (listaProdutos[item].ItemName))}
                      value={produto}
                      getOptionSelected={() => true}
                      onChange={(index, valor) => { setCodigoProduto(valor) }}
                      renderInput={(params) => (<TextField variant='outlined' value={produto} {...params} label='Produto' />)}
                     />


                    <TextField id='cliente'
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      style={{ width: 300, margin: 10 }}
                      value={cliente}
                      variant='outlined'
                      label='Cliente'
                      onChange={(e) => setCliente(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer> {/* TERCEIRA LINHA */}
                  <GridItem style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField id='obs'
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      style={{ width: 300, margin: 10 }}
                      variant='outlined'
                      value={obs}
                      label='Observação'
                      onChange={(e) => setObs(e.target.value)}
                    />

                    <TextField id='contato'
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      style={{ width: 300, margin: 10 }}
                      variant='outlined'
                      value={contato}
                      label='Contato'
                      onChange={(e) => setContato(e.target.value)}
                    />
                    <TextField id='roteiro'
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      style={{ width: 300, margin: 10 }}
                      variant='outlined'
                      value={roteiro}
                      label='Roteiro'
                      onChange={(e) => setRoteiro(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem style={{ display: 'flex', flexDirection: 'row' }}>


                    <Autocomplete
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      id='Emails Transportadoras'
                      freeSolo
                      multiple
                      style={{ width: 300, margin: 10 }}
                      options={Object.keys(listaEmails).map((item, index) => (listaEmails[item].emails))}
                      onChange={(event, newValue) => setEmailTransp(newValue)}
                      renderInput={(params) => (<TextField value={emailTransp} variant='outlined' {...params} label='Emails Transportadoras' />)}
                    />

                    <Autocomplete
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      id='Email'
                      freeSolo
                      multiple
                      style={{ width: 300, margin: 10 }}
                      options={Object.keys(listaEmails).map((item, index) => (listaEmails[item].emails))}
                      onChange={(event, newValue) => setEmail(newValue)}
                      renderInput={(params) => (<TextField value={email} variant='outlined' {...params} label='Demais Emails' />)}
                    />

                    <Autocomplete
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      id='Email'
                      freeSolo
                      multiple
                      style={{ width: 300, margin: 10 }}
                      options={groupEmails.map((grupo) => grupo.nome_grupo)}
                      onChange={(event, newValue) => setEmailGroup(newValue)}
                      renderInput={(params) => (<TextField value={email} variant='outlined' {...params} label='Grupo de emails' />)}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem style={{ display: 'flex', flexDirection: 'row' }}>

                    <TextField id='numeroDI'
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      style={{ width: 300, margin: 10 }}
                      value={numeroDI}
                      variant='outlined'
                      label='Número DI'
                      onChange={(e) => (setNumeroDI(e.target.value))}
                    />

                    <TextField id='nomeNavio'
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      style={{ width: 300, margin: 10 }}
                      value={nomeNavio}
                      variant='outlined'
                      label='Nome do Navio'
                      onChange={(e) => setNomeNavio(e.target.value)}
                    />

                    <TextField id='roteiro'
                      disabled={tipoCarregamento === '' || tipoCarregamento === null}
                      style={{ width: 300, margin: 10 }}
                      variant='outlined'
                      value={nomeMotorista}
                      label='Nome do Motorista'
                      onChange={(e) => setNomeMotorista(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer style={{ paddingTop: 10 }}>
                  <GridItem>
                    <Button disabled={tipoCarregamento === '' || tipoCarregamento === null} onClick={() => setPlacaQuantidade([...placaQuantidade, { placa: '', quantidade: 0, dataChegada: '' }])}>Adicionar Placa</Button>
                    <Button disabled={tipoCarregamento === '' || tipoCarregamento === null} onClick={() => removerPlaca()}>Remover Placa</Button>
                  </GridItem>
                </GridContainer>

                <GridContainer style={{ paddingLeft: 5 }}>
                  {placaQuantidade.map((item, index) => (
                    <GridContainer key={index} style={{ paddingLeft: 20, paddingTop: 10 }}>
                      <GridItem >
                        <TextField id='placa'
                          disabled={tipoCarregamento === '' || tipoCarregamento === null}
                          style={{ width: 135 }}
                          value={item['placa']}
                          variant='outlined'
                          label='Placa'
                          onChange={(e) => mudarInput(e.target.value, index, 'placa')}
                        />
                        <TextField id='quantidade'
                          disabled={tipoCarregamento === '' || tipoCarregamento === null}
                          style={{ width: 135 }}
                          variant='outlined'
                          value={item['quantidade']}
                          label='Quantidade'
                          type='number'
                          onChange={(e) => mudarInput(e.target.value, index, 'quantidade')}
                        />
                        <TextField id='dataChegada'
                          disabled={tipoCarregamento === '' || tipoCarregamento === null}
                          style={{ width: 190 }}
                          variant='outlined'
                          value={item['dataChegada']}
                          label='Previsão de Chegada'
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ max: '9999-12-31' }}
                          type='date'
                          onChange={(e) => mudarInput(e.target.value, index, 'dataChegada')}
                        />
                      </GridItem>
                    </GridContainer>

                  ))}
                </GridContainer>


                <GridContainer style={{ paddingTop: 10 }}>
                  <GridItem>
                    <Button disabled={tipoCarregamento === '' || tipoCarregamento === null} style={{ width: 100 }} variant="contained" component="label" >Anexos<input type="file" id="myfileinput" multiple onChange={(e) => {
                      filesToBase64()
                      setArquivosSelecionados(e.target.files)
                    }} hidden /></Button>
                  </GridItem>
                  {Object.keys(arquivosSelecionados).map((file) => <div style={{ paddingTop: 15 }}>&nbsp;{arquivosSelecionados[file].name}&nbsp;|</div>)}
                </GridContainer>

                <GridContainer>
                  <GridItem >
                    {tipoCarregamento === 'Vinculo Placas Paranagua' ? null : <Button disabled={tipoCarregamento === '' || tipoCarregamento === null || salvando} onClick={() => { if (camposObrigatorios()) { saveCarregamento() } }} style={{ width: 100 }}>Salvar</Button>}
                    {(salvando) ? <div> <Loader
                      type="Circles"
                      color="#00BFFF"
                      height={20}
                      width={20}
                      timeout={2000000} // 3 secs
                    /> enviado arquivo {stateArquivos} de {file.length} </div> : null}
                    <div style={{ display: 'flex', flexDirection: 'columns' }}>


                      <Accordion style={{ width: '100%' }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"

                        >
                          <Typography className={classes.heading}>Placas</Typography>


                        </AccordionSummary>
                        <div style={{ border: "1px solid", margin: 10 }}>
                          {Object.keys(placasAgendadas).map((index) =>
                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                              <Typography>
                                Placa: {placasAgendadas[index].placa}

                              </Typography>
                              <Typography>
                                Quantidade {placasAgendadas[index].quantidade}
                              </Typography>
                              {tipoCarregamento === 'Vinculo Placas Paranagua' ?
                                <div>
                                  <TextField id='Nome Motorista'
                                    disabled={tipoCarregamento === '' || tipoCarregamento === null}
                                    style={{ width: 135 }}
                                    variant='outlined'
                                    defaultValue={placasAgendadas[index].motorista}
                                    label='Nome motorista'
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => setUpdatePlacas(e.target.value, placasAgendadas[index].id, 'motorista')}
                                  />
                                  <TextField id='Celular'
                                    disabled={tipoCarregamento === '' || tipoCarregamento === null}
                                    style={{ width: 135 }}
                                    variant='outlined'
                                    defaultValue={placasAgendadas[index].celular}
                                    label='Celular'
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => setUpdatePlacas(e.target.value, placasAgendadas[index].id, 'celular')}
                                  />
                                  <TextField
                                    id="date"
                                    label="Birthday"
                                    type="date"
                                    defaultValue={placasAgendadas[index].data}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => setUpdatePlacas(e.target.value, placasAgendadas[index].id, 'data')}

                                  />
                                  <TextField
                                    id="time"
                                    label="Alarm clock"
                                    type="time"
                                    defaultValue={placasAgendadas[index].hora}
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      step: 300, // 5 min
                                    }}
                                    onChange={(e) => setUpdatePlacas(e.target.value, placasAgendadas[index].id, 'hora')}

                                  /> </div> : null}
                              <Button style={{ backgroundColor: '#12824C', color: '#FFFFFF' }} onClick={() => removePlacaAgendada(placasAgendadas[index].placa, placasAgendadas[index].id, index)}> Remover placa {placasAgendadas[index].placa}</Button>

                            </AccordionDetails>
                          )}
                          {tipoCarregamento === 'Vinculo Placas Paranagua' ?
                            <Button disabled={tipoCarregamento === '' || tipoCarregamento === null} onClick={() => salvarInformacoes()}>Salvar modificaçoes placa</Button>
                            : null}
                        </div>
                      </Accordion>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>


            <div style={{ fontWeight: 'bold' }}>Agendamentos Pendentes</div>
            {/* Tabela ID 0 */}
            <TableContainer style={{ tableLayout: 'fixed', overflow: 'scroll', height: 300, width: '100%' }}>
              <Table stickyHeader style={{ tableLayout: 'fixed', borderCollapse: 'collapse' }} >
                <TableHead style={{ backgroundColor: '#6699FF' }}>
                  <TableRow>
                    <TableCell style={{ width: 50, fontSize: 13, textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Selecionar</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}> Origem</TableCell>
                    <TableCell style={{ width: 300, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Lote/Placa/Quantidade/Previsão</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Local de Carregamento</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Obs</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Número do Pedido</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Produto</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Embalagem </TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Cliente</TableCell>
                    <TableCell style={{ width: 250, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Roteiro</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Destino / UF</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Transportadora</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Frete</TableCell>
                    <TableCell style={{ width: 300, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Email</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Número DI</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Tipo de Carregamento</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Nome do Navio</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', color: 'white', backgroundColor: '#6699FF' }}>Contato</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ backgroundColor: 'white' }}>
                  {Object.keys(agendamento).map((item, index) => {
                    if (agendamento[item].idClick === 0) {
                      return (
                          <TableRow key={index} style={{ backgroundColor: agendamentoArray[index] === true ? '#cfe2f3' : 'white' }} >
                          <TableCell style={{ color: 'white', left: 0, top: 0 }}>
                            {agendamentoArray[index] === true ? <IconButton
                              style={{ color: '#A3CC51' }}
                              aria-label="toggle password visibility"
                              onClick={() => (alterSelected(index, false), montaUpdateEmail(agendamento[item].id))}> <CheckCircleOutlineIcon />
                            </IconButton> : <IconButton aria-label="toggle password visibility"
                              onClick={() => (alterSelected(index, true))}>
                              <RadioButtonUncheckedIcon />
                            </IconButton>}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].praca}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {Object.keys(JSON.parse(agendamento[item].placa)).map((i, index) => <div key={index} style={{ border: '1px solid rgba(0, 0, 0, .5)' }}>{agendamento[item].pedFatCliente}-{JSON.parse(agendamento[item].placa)[i].numero_carregamento}, {JSON.parse(agendamento[item].placa)[i].placa}, {JSON.parse(agendamento[item].placa)[i].quantidade}, {JSON.parse(agendamento[item].placa)[i].dataChegada}</div>)}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].localCarregamento}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].obs}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].pedFatCliente}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].produto}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].embalagem}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].cliente}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].roteiro}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].destino}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].transportadora}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].frete}</TableCell>
                          <TableCell key={index} align="center" style={{ padding: 0 }}>
                            {agendamentoArray[index] !== true ? agendamento[item].email :
                              <TextField
                                key='index'
                                variant='outlined'
                                onChange={(e) => (setEmailAlterado(e.target.value))}
                              
                                style={{ padding: 0 }}

                                InputProps={{
                                  disableUnderline: true,
                                  classes: { notchedOutline: classes.noBorder },

                                }}
                              />}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].numeroDI}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].tipoCarregamento}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].nomeNavio}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {agendamento[item].contato}</TableCell>
                          <TableCell style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button disabled={enviando} onClick={() => enviarEmail(agendamento[item].id, 1)}>Enviar</Button>
                            <Button disabled={deletando} onClick={() => deletaRota(agendamento[item].id, 2)}>Deletar</Button>
                            <Button disabled={deletando} onClick={() => DownloadPDF(agendamento[item].id)}>Visualizar</Button>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>


            <div style={{ fontWeight: 'bold', paddingTop: 40 }}>Histórico de Agendamentos</div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <a> <Button onClick={() => baixarHistorico()}>BAIXAR HISTÓRICO</Button></a>
              {relPng !== '' ? <a> <Button onClick={() => baixarRelPgn()}>BAIXAR  DE HISTÓRICO CARREGAMENTOS PARANGUÁ</Button></a> : <Loader
                type="Circles"
                color="#00BFFF"
                height={20}
                width={20}
                timeout={2000000} // 3 secs
              />}

              {relPng !== '' ? <a> <Button onClick={() => baixarRelPgn()}>BAIXAR  DE HISTÓRICO CARREGAMENTOS PARANGUÁ</Button></a> : <Loader
                type="Circles"
                color="#00BFFF"
                height={20}
                width={20}
                timeout={2000000} // 3 secs
              />}

              <div style={{ marginTop: 10, marginBottom: 10, display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                <TextField
                  style={{ width: 350 }}
                  value={filtro}
                  onChange={(e) => e.target.value !== '' ? setFiltro(e.target.value) : setFiltro('')}
                  multiline
                  size='small'
                  label='Filtro'
                  variant="outlined" />

                <Autocomplete
                  style={{ width: 200 }}
                  getOptionLabel={(option) => option.label}
                  getOptionSelected={(option, value) => option.label === value.label}
                  onChange={(e, v) => v === null ? setFiltroColuna('') : (setFiltroColuna(v.value), handleFiltro(v.value, filtro))}
                  options={filtroColunaOptions}
                  renderInput={(params) => <TextField {...params} variant='outlined' size="small" label="Coluna" />}
                />

                <div style={{ marginLeft: 10 }}><button onClick={() => handleFiltro(filtroColuna, filtro)}>Pesquisar</button> </div>
                <div style={{ marginLeft: 5 }}><button onClick={() => clearFiltro()}>Limpar Filtro</button></div>
              </div>
            </div>

            {/* Tabela ID 1 */}
            <TableContainer style={{ tableLayout: 'fixed', overflow: 'scroll', height: 600, width: '100%', marginBottom: 120 }}>
              <Table stickyHeader style={{ tableLayout: 'fixed', borderCollapse: 'collapse' }} >
                <TableHead>
                  <TableRow >
                    <TableCell style={{ width: 150, fontSize: 13, textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Data de Envio</TableCell>
                    <TableCell style ={{width: 150, fontSize: 13, textAlign: 'center',color: 'white',backgroundColor: '#6699FF'}}>Ordem de Carregamento</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Origem</TableCell>
                    <TableCell style={{ width: 300, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Lote/Placa/Quantidade/Previsão</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Local de Carregamento</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Obs</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Número do Pedido</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Código Item</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Produto</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Embalagem </TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Cliente</TableCell>
                    <TableCell style={{ width: 250, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Roteiro</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Destino / UF</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Transportadora</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Frete</TableCell>
                    <TableCell style={{ width: 300, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Email</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Número DI</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Tipo de Carregamento</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Nome do Navio</TableCell>
                    <TableCell style={{ width: 150, fontSize: 13, borderLeft: '1px solid #ddd', textAlign: 'center', backgroundColor: '#6699FF', color: 'white' }}>Contato</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ backgroundColor: 'white' }}>
                  {Object.keys(auxAgendamento).map((item, index) => {
                    if (auxAgendamento[item].idClick === 1) {
                      return (

                        <TableRow key={index}>
                          
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].data}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].id}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].praca}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {Object.keys(JSON.parse(auxAgendamento[item].placa)).map((i, index) => <div key={index} style={{ border: '1px solid rgba(0, 0, 0, .5)' }}>{auxAgendamento[item].pedFatCliente}-{JSON.parse(auxAgendamento[item].placa)[i].numero_carregamento}, {JSON.parse(auxAgendamento[item].placa)[i].placa}, {JSON.parse(auxAgendamento[item].placa)[i].quantidade}, {JSON.parse(auxAgendamento[item].placa)[i].dataChegada}</div>)}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].localCarregamento}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].obs}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].pedFatCliente}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].itemCode}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].produto}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].embalagem}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].cliente}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].roteiro}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].destino}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].transportadora}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].frete}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].email}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].numeroDI}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].tipoCarregamento}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].nomeNavio}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}> {auxAgendamento[item].contato}</TableCell>
                        </TableRow>


                      )
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>

          </GridItem>
        </GridContainer>
        <div>
        {abrirvizualizador !== false ?
                        <div style={{ width: 1000 }} >
                            <Dialog  open={abrirvizualizador} onClose={handleClickClose2} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title"><b>Dados do Agendamento</b></DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>                                           
                                        {parse(html)}
                                       
                                        </div>
                                    </DialogContentText>


                                </DialogContent>
                                <DialogActions style={{ justifyContent: 'center' }} >
                                    <Button onClick={(handleClickClose2)}  style={{ marginRight: 20 }}>
                                        Cancelar
                                    </Button>

                                    <Button onClick={() => (printDocument(),handleClickClose2)} style={{ marginLeft: 20 }}>
                                        Baixar
                                    </Button>

                                </DialogActions>
                            </Dialog>
                        </div> : null}
        </div>
      </div > : null
  );


}

function mapStateToProps(state) {
  return {
    token: state.token,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setToken: setToken,
      setUser: setUser,
    },
    dispatch
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(Agendamento);
